import React from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { IconButton } from 'components';
import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import { CancelableBannerInfo } from 'components/discussions';
import { useAuth } from 'lib/providers/AuthProvider';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';

import styles from './StartDiscussion.module.scss';

const StartDiscussion = ({ placeholder, className }: { placeholder: string; className?: string }) => {
  const [t] = useTranslation('discussions');
  const { basicParticipant } = useAuth();
  const { openNewPostModal, initializePoll, isCandidatePage, societyName, showAdminApprovalInfo, clearAdminApprovalInfo, isManagerUser } =
    useDiscussions();

  const handleInitializePostPoll = async () => {
    try {
      await openNewPostModal();
      initializePoll();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={cx(styles.container, className, { [styles['society-page']]: isCandidatePage })}>
      <HuzzleUserAvatar user={basicParticipant} />
      <div className={styles.content}>
        <button className={styles.input} onClick={openNewPostModal}>
          {placeholder}
        </button>
        {isCandidatePage && showAdminApprovalInfo ? (
          <CancelableBannerInfo onCancel={clearAdminApprovalInfo}>
            {t('new-discussion.info-holder', { society_name: societyName })}
          </CancelableBannerInfo>
        ) : null}
        <div className={styles['post-attachment-actions']}>
          <IconButton
            iconName="icon_image"
            size="auto"
            className={styles['icon-button']}
            iconClassName={styles.icon}
            onClick={openNewPostModal}
            htmlFor="upload-post-image"
          />
          {isManagerUser && (
            <IconButton
              iconName="icon_document-2"
              size="auto"
              className={cx(styles['icon-button'], styles['document-icon'])}
              iconClassName={styles.icon}
              onClick={openNewPostModal}
              htmlFor="upload-post-document"
            />
          )}
          <IconButton
            iconName="unordered_list"
            size="auto"
            className={styles['icon-button']}
            iconClassName={styles.icon}
            onClick={handleInitializePostPoll}
          />
        </div>
      </div>
    </div>
  );
};

StartDiscussion.displayName = 'StartDiscussion';

export default StartDiscussion;
