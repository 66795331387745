import { useTranslation } from 'next-i18next';

import { ButtonV3 } from 'components/ComponentV2';
import Spinner from 'components/Spinner';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './SubscriberRequireApprovalContent.module.scss';

type SubscriberRequireApprovalContentProps = {
  message: string;
  onSubscribe: (id: string) => void;
  isAwaitingApproval?: boolean;
};

const SubscriberRequireApprovalContent: React.FC<SubscriberRequireApprovalContentProps> = ({ message, onSubscribe, isAwaitingApproval }) => {
  const [tCommon] = useTranslation('common');
  const { studentSocietyId } = useDiscussions();
  const { isAuthLoading } = useAuth();

  return (
    <div className={styles.container}>
      {isAuthLoading ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          <p className={styles.emoji}>🙄</p>
          <p className={styles.message}>{message}</p>
          {!isAwaitingApproval ? (
            <ButtonV3 startIcon="icon_plus" type="button" color="primary" onClick={() => onSubscribe(studentSocietyId)}>
              {tCommon('join')}
            </ButtonV3>
          ) : null}
        </div>
      )}
    </div>
  );
};

SubscriberRequireApprovalContent.displayName = 'SubscriberRequireApprovalContent';

export default SubscriberRequireApprovalContent;
