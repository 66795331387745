import { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { Icon } from 'components';
import { Post } from 'components/discussions';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';

import styles from './PendingPosts.module.scss';

type PendingPostsProps = {
  className?: string;
};

const Header = (): ReactElement => {
  const [t] = useTranslation('discussions');
  const { closePendingPosts } = useDiscussions();

  return (
    <header className={styles.header}>
      <div>
        <button className={styles.breadcrumb} onClick={closePendingPosts}>
          <Icon iconName="chevron-left" size="medium" />
          <p>{t('pending-posts.header.back-to-discussions')}</p>
        </button>
        <h1 className={styles.title}>{t('pending-posts.header.title')}</h1>
      </div>
    </header>
  );
};

const PendingPosts: React.FC<PendingPostsProps> = ({ className }) => {
  const { pendingPosts, onApprovePost, onDeclinePost } = useDiscussions();

  return (
    <div className={cx(styles.container, className)}>
      <Header />
      <div className={styles.content}>
        {pendingPosts.map((post) => (
          <Post key={post.id} post={post} className={styles.post} onApprove={onApprovePost} onDecline={onDeclinePost} minimalView={true} />
        ))}
      </div>
    </div>
  );
};

PendingPosts.displayName = 'PendingPosts';

export default PendingPosts;
