import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { signInModalInstance } from 'components/candidate/SignInModal';
import { useNotification } from 'hooks/useNotification';
import {
  TPostPayload,
  TUserableType,
  TPostState,
  TSocietyDiscussionsSettings,
  PaginatedPostsResponse,
  PaginatedMediaAttachmentsResponse,
  PaginatedDocumentAttachmentsResponse,
  TPost,
  TMediaPost,
  TDocumentPost,
  TPostLike,
  TPostType,
  TBasicStudentSociety,
} from 'lib/models/discussion';
import { TPostParticipant } from 'lib/models/student-society';
import { API_ROUTES } from 'lib/api-routes';
import { apiInstance } from 'lib/utils/axios';
import {
  approvePost,
  createSocietyPost,
  declinePost,
  deleteSocietyPost,
  likePost,
  unlikePost,
  updateStudentSociety,
  voteOnPollOption,
} from 'lib/services/discussion';
import { getSocietyDiscussionsSettings } from 'lib/utils/discussion';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';
import { RoomAttributes, RoomResponse } from 'lib/models/candidate-messages';
import { parseResponse } from 'lib/utils/parser';
import { POST_ATTACHMENTS_POLLING_INTERVAL, POSTS_POLLING_INTERVAL } from 'lib/consts';

export function useDiscussionsInternalHook({
  studentSociety,
  isCandidatePage = false,
  isRecruiterPage = false,
  isManagerPage = false,
  isSocietyMember = false,
  skipAttachments = false,
  initialPosts = [],
  initialMediaAttachments = [],
  initialDocumentAttachments = [],
}: {
  studentSociety: TBasicStudentSociety;
  isCandidatePage?: boolean;
  isRecruiterPage?: boolean;
  isManagerPage?: boolean;
  isSocietyMember?: boolean;
  skipAttachments?: boolean;
  initialPosts?: Array<TPost>;
  initialMediaAttachments?: TMediaPost['media'];
  initialDocumentAttachments?: TDocumentPost['documents'];
}) {
  const [t] = useTranslation('discussions');
  const {
    query: { discussion_id: queryDiscussionId },
  } = useRouter();
  const notificationInstance = useNotification();
  const { user, isCandidate, isManager, isRecruiter, updatePartialManager } = useAuth();

  const userableType = (user?.userable_type || TUserableType.Candidate) as TUserableType;
  const { id: student_society_id, slug, name: societyName, subscribers_require_approval } = studentSociety;
  const postsPath = `${API_ROUTES.STUDENT_SOCIETY_POSTS.replace(':student_society_id', student_society_id)}?page=1&per_page=100000`;
  const mediaPath = skipAttachments
    ? null
    : `${API_ROUTES.STUDENT_SOCIETY_POSTS.replace(':student_society_id', student_society_id)}/attachments?type=MediaPost&page=1&per_page=100000`;
  const documentsPath = skipAttachments
    ? null
    : `${API_ROUTES.STUDENT_SOCIETY_POSTS.replace(':student_society_id', student_society_id)}/attachments?type=DocumentPost&page=1&per_page=100000`;
  const societyDiscussionsSettings = useMemo(() => getSocietyDiscussionsSettings(studentSociety), [studentSociety]);
  const [showAdminApprovalInfo, setAdminApprovalInfo] = useState(false);
  const [showNoDiscussionInfo, setNoDiscussionInfo] = useState(false);
  const noDiscussionInfoClearedRef = useRef(false);
  const postViewedRef = useRef<Record<string, boolean>>({});
  const [likeLoadingPostId, setLikeLoadingPostId] = useState<string | null>(null);

  const [isNewPostModalOpen, setIsNewPostModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const isPostsFetched = useRef(false);
  const [isPostCreationLoading, setIsPostCreationLoading] = useState(false);
  const [initializedPoll, setInitializedPoll] = useState(false);
  const [showPendingPosts, setShowPendingPosts] = useState(false);
  const scrollIntoViewRef = useRef(false);
  const {
    data: postsData,
    mutate: mutatePosts,
    isLoading: isPostsLoading,
  } = useSWR<AxiosResponse<PaginatedPostsResponse>>(postsPath, apiInstance.get, {
    fallbackData: { data: { data: initialPosts, meta: { total: initialPosts.length } } } as AxiosResponse<PaginatedPostsResponse>,
    refreshInterval: POSTS_POLLING_INTERVAL,
  });
  const { data: mediaAttachmentsData } = useSWR<AxiosResponse<PaginatedMediaAttachmentsResponse>>(mediaPath, apiInstance.get, {
    fallbackData: {
      data: { data: initialMediaAttachments, meta: { total: initialMediaAttachments.length } },
    } as AxiosResponse<PaginatedMediaAttachmentsResponse>,
    refreshInterval: POST_ATTACHMENTS_POLLING_INTERVAL,
  });
  const { data: documentAttachmentsData } = useSWR<AxiosResponse<PaginatedDocumentAttachmentsResponse>>(documentsPath, apiInstance.get, {
    fallbackData: {
      data: { data: initialDocumentAttachments, meta: { total: initialDocumentAttachments.length } },
    } as AxiosResponse<PaginatedDocumentAttachmentsResponse>,
    refreshInterval: POST_ATTACHMENTS_POLLING_INTERVAL,
  });

  const changeSocietyDiscussionSettings = useCallback(
    async (settings: TSocietyDiscussionsSettings) => {
      const updatedSociety = await updateStudentSociety({ settings });
      updatePartialManager({ student_society: updatedSociety });
    },
    [updatePartialManager]
  );

  useEffect(() => {
    if (!isPostsLoading && isPostsFetched.current === false) {
      isPostsFetched.current = true;
    }
  }, [isPostsFetched, isPostsLoading]);

  const initializePoll = useCallback(() => {
    setInitializedPoll(true);
  }, []);

  const deinitializePoll = useCallback(() => {
    setInitializedPoll(false);
  }, []);

  const openLoginPopupForCandidate = useCallback(() => {
    async function resolveFn(resolve: (value: boolean) => void, reject: () => void) {
      if (isCandidatePage) {
        if (isCandidate) {
          resolve(true);
        } else {
          try {
            await signInModalInstance();
            resolve(true);
          } catch (error) {
            reject();
          }
        }
      } else {
        resolve(true);
      }
    }

    return new Promise(resolveFn);
  }, [isCandidatePage, isCandidate, signInModalInstance]);

  const openNewPostModal = useCallback(async () => {
    await openLoginPopupForCandidate();
    setIsNewPostModalOpen(true);
  }, [openLoginPopupForCandidate]);

  const closeNewPostModal = useCallback(() => {
    setIsNewPostModalOpen(false);
  }, []);

  const openSettingsModal = useCallback(() => {
    setIsSettingsModalOpen(true);
  }, []);

  const closeSettingsModal = useCallback(() => {
    setIsSettingsModalOpen(false);
  }, []);

  const openPendingPosts = useCallback(() => {
    setShowPendingPosts(true);
  }, []);

  const closePendingPosts = useCallback(() => {
    setShowPendingPosts(false);
  }, []);

  const updatePostsList = useCallback(
    ({ newPost, postId, parentPostId }: { newPost?: TPost; postId: string; parentPostId?: string }) => {
      mutatePosts(
        (oldPostsData) => {
          if (!oldPostsData) return oldPostsData;
          let posts: Array<TPost> = [];
          if (newPost) {
            posts = parentPostId
              ? oldPostsData.data.data.map((post) => {
                  if (post.id === parentPostId) {
                    if (newPost.post_type !== TPostType.DocumentPost) {
                      post.comments = [newPost, ...post.comments];
                    }
                  }
                  return { ...post };
                })
              : [newPost, ...oldPostsData.data.data];
          } else {
            posts = parentPostId
              ? oldPostsData.data.data.map((post) => {
                  if (post.id === parentPostId) {
                    post.comments = post.comments.filter((comment) => comment.id !== postId);
                  }
                  return { ...post };
                })
              : oldPostsData.data.data.filter((post) => post.id !== postId);
          }

          return {
            ...oldPostsData,
            data: {
              ...oldPostsData.data,
              data: posts,
            },
          };
        },
        {
          revalidate: false,
        }
      );
    },
    [mutatePosts]
  );

  const addPost = useCallback(
    async (postPayload: TPostPayload) => {
      try {
        setIsPostCreationLoading(true);
        notificationInstance.acknowledge({
          message: t('posting'),
          loading: true,
          timeout: 100000,
        });
        const { data: newPost } = await createSocietyPost({ student_society_id, payload: postPayload });
        updatePostsList({ newPost, postId: newPost.id, parentPostId: postPayload.parent_post_id });
        notificationInstance.acknowledge({
          message: !isManager
            ? societyDiscussionsSettings.is_post_approval_required && !postPayload.parent_post_id
              ? t('post-sent-for-approval')
              : t('posted')
            : t('posted'),
          timeout: 5000,
        });
        return newPost;
      } catch (error) {
        console.error('Error creating post', error);
        notificationInstance.acknowledge({
          message: t('failed-to-post'),
          timeout: 10000,
        });
      } finally {
        setIsPostCreationLoading(false);
      }
    },
    [updatePostsList, societyDiscussionsSettings, isManager]
  );

  const deletePost = useCallback(
    async ({ postId, parentPostId }: { postId: string; parentPostId?: string }) => {
      try {
        await deleteSocietyPost({ post_id: postId });
        updatePostsList({ postId: postId, parentPostId });
        notificationInstance.acknowledge({
          message: t('deleted'),
          timeout: 5000,
        });
      } catch (error) {
        console.error('Error deleting post', error);
      }
    },
    [mutatePosts]
  );

  const updatePostLikes = useCallback(
    ({ postId, postLike, likeId }: { postId: string; likeId: string; postLike?: TPostLike }) => {
      mutatePosts(
        (olderPostsData) => {
          if (!olderPostsData) return olderPostsData;
          const updatedPosts = olderPostsData.data.data.map((post) => {
            if (post.id === postId) {
              if (postLike) {
                post.likes = [...post.likes, postLike];
              } else {
                post.likes = post.likes.filter((like) => like.id !== likeId);
              }
            } else {
              post.comments = post.comments.map((comment) => {
                if (comment.id === postId) {
                  if (postLike) {
                    comment.likes = [...comment.likes, postLike];
                  } else {
                    comment.likes = comment.likes.filter((like) => like.id !== likeId);
                  }
                }
                return { ...comment };
              });
            }
            return { ...post };
          });

          return {
            ...olderPostsData,
            data: {
              ...olderPostsData.data,
              data: updatedPosts,
            },
          };
        },
        {
          revalidate: false,
        }
      );
    },
    [mutatePosts]
  );

  const onLikePost = useCallback(
    async ({ postId }: { postId: string }) => {
      try {
        setLikeLoadingPostId(postId);
        await openLoginPopupForCandidate();
        const { data: postLike } = await likePost({ post_id: postId, userable_type: userableType });
        updatePostLikes({ postId, likeId: postLike.id, postLike });
      } catch (error) {
        console.error('Error liking post', error);
      } finally {
        setLikeLoadingPostId(null);
      }
    },
    [updatePostLikes, userableType, openLoginPopupForCandidate]
  );

  const onUnlikePost = useCallback(
    async ({ postId, likeId }: { postId: string; likeId: string }) => {
      try {
        setLikeLoadingPostId(postId);
        await unlikePost({ like_id: likeId });
        updatePostLikes({ postId, likeId });
      } catch (error) {
        console.error('Error liking post', error);
      } finally {
        setLikeLoadingPostId(null);
      }
    },
    [updatePostLikes, student_society_id]
  );

  const onCopyPostURL = useCallback(
    async (postId: string) => {
      if (!slug) return;
      const route = `${window.location.origin}${PAGE_ROUTES.SOCIETY_PUBLIC.replace('[slug]', slug)}?discussion_id=${postId}`;
      try {
        await navigator.clipboard.writeText(route);
        notificationInstance.acknowledge({
          message: t('copied-post-url'),
          timeout: 5000,
        });
      } catch {
        console.error('Failed to copy post URL');
      }
    },
    [slug]
  );

  const { canPostOptions, canReplyOptions, showMediaUploadsFromOptions } = useMemo(() => {
    const baseOptions = [
      {
        id: TPostParticipant.ANYONE,
        label: t('post-participant.anyone'),
        value: TPostParticipant.ANYONE,
      },
      {
        id: TPostParticipant.TEAM_ONLY,
        label: t('post-participant.team_only'),
        value: TPostParticipant.TEAM_ONLY,
      },
      {
        id: TPostParticipant.MEMBERS_ONLY,
        label: t('post-participant.members_only'),
        value: TPostParticipant.MEMBERS_ONLY,
      },
    ];

    const canReplyOptions = [
      ...baseOptions,
      {
        id: TPostParticipant.NO_ONE,
        label: t('post-participant.no_one'),
        value: TPostParticipant.NO_ONE,
      },
    ];

    return {
      canPostOptions: baseOptions,
      canReplyOptions: canReplyOptions,
      showMediaUploadsFromOptions: baseOptions,
    };
  }, [t]);

  const posts = useMemo(() => postsData?.data.data || [], [postsData]);

  const pendingPosts = useMemo(() => {
    return posts.filter((post) => post.state === TPostState.Draft);
  }, [posts]);

  const approvedPosts = useMemo(() => {
    return posts
      .filter((post) => post.state === TPostState.Approved)
      .map((post) => {
        return {
          ...post,
          comments: post.comments.sort((commentA, commentB) => new Date(commentB.created_at).getTime() - new Date(commentA.created_at).getTime()),
        };
      });
  }, [posts]);

  useEffect(() => {
    setAdminApprovalInfo(societyDiscussionsSettings.is_post_approval_required);
  }, [societyDiscussionsSettings]);

  useEffect(() => {
    if (isPostsLoading || typeof postsData === 'undefined' || noDiscussionInfoClearedRef.current) return;

    setNoDiscussionInfo(postsData.data.data.length === 0);
  }, [postsData, isPostsLoading]);

  const updatePostsState = useCallback(
    ({ postId, state }: { postId: string; state: TPostState }) => {
      mutatePosts((olderPostsData) => {
        if (!olderPostsData) return olderPostsData;
        const updatedPosts = olderPostsData.data.data.map((post) => {
          if (post.id === postId) {
            post.state = state;
          }
          return { ...post };
        });

        return {
          ...olderPostsData,
          data: {
            ...olderPostsData.data,
            data: updatedPosts,
          },
        };
      });
    },
    [mutatePosts]
  );

  const onApprovePost = useCallback(
    async (postId: string) => {
      try {
        await approvePost({ post_id: postId });
        notificationInstance.acknowledge({
          message: t('pending-posts.post.approved'),
          timeout: 5000,
        });
        updatePostsState({ postId, state: TPostState.Approved });
      } catch (error) {
        console.error('Error approving post', error);
      }
    },
    [updatePostsState]
  );

  const onDeclinePost = useCallback(
    async (postId: string) => {
      try {
        await declinePost({ post_id: postId });
        notificationInstance.acknowledge({
          message: t('pending-posts.post.declined'),
          timeout: 5000,
        });
        updatePostsState({ postId, state: TPostState.Declined });
      } catch (error) {
        console.error('Error declining post', error);
      }
    },
    [updatePostsState]
  );

  const mediaAttachments = useMemo(() => {
    return mediaAttachmentsData?.data.data || [];
  }, [mediaAttachmentsData]);

  const documentAttachments = useMemo(() => {
    return documentAttachmentsData?.data.data || [];
  }, [documentAttachmentsData]);

  const answerOnPoll = useCallback(
    async ({ postId, pollAnswerOptionId }: { postId: string; pollAnswerOptionId: string }) => {
      try {
        await openLoginPopupForCandidate();
        const { data: answer } = await voteOnPollOption({ post_id: postId, poll_answer_option_id: pollAnswerOptionId, userable_type: userableType });
        mutatePosts(
          (olderPostsData) => {
            if (!olderPostsData) return olderPostsData;
            const updatedPosts = olderPostsData.data.data.map((post) => {
              if (post.id === postId && post.post_type === TPostType.PollPost) {
                post.poll_answer_options = post.poll_answer_options.map((option) => {
                  if (option.id === pollAnswerOptionId) {
                    option.poll_answers = [...option.poll_answers, answer];
                  }
                  return { ...option };
                });
              }
              return { ...post };
            });

            return {
              ...olderPostsData,
              data: {
                ...olderPostsData.data,
                data: updatedPosts,
              },
            };
          },
          {
            revalidate: false,
          }
        );
      } catch (error) {
        console.error('Error answering poll option', error);
      }
    },
    [userableType, openLoginPopupForCandidate]
  );

  const clearAdminApprovalInfo = useCallback(() => {
    setAdminApprovalInfo(false);
  }, []);

  const clearNoDiscussionInfo = useCallback(() => {
    noDiscussionInfoClearedRef.current = true;
    setNoDiscussionInfo(false);
  }, []);

  const onMessageUser = useCallback(async (post: TPost) => {
    const participable_id = post.posted_by_user_company?.id || post.user.userable_id;
    const participable_type = post.posted_by_user_company ? 'Company' : post.user.userable_type;

    const body = {
      room: {
        participants_attributes: [{ participable_id, participable_type }],
      },
    };

    try {
      const { data: roomResponse } = await apiInstance.post<RoomResponse>(API_ROUTES.ROOMS, body);
      const room = parseResponse<RoomAttributes>(roomResponse);
      if (room) {
        const route = `${PAGE_ROUTES.MESSAGES}?roomId=${room.id}`;
        window.open(route, '_blank');
      }
    } catch (error) {
      console.error('Error creating room', error);
    }
  }, []);

  const onPostView = useCallback(
    async (postId: string) => {
      try {
        mutatePosts(
          (oldPostsData) => {
            if (!oldPostsData) return oldPostsData;

            const updatedPosts = oldPostsData.data.data.map((post) => {
              if (post.id === postId) {
                post.viewed_count += 1;
                post.has_viewed = true;
              }
              return { ...post };
            });

            return {
              ...oldPostsData,
              data: {
                ...oldPostsData.data,
                data: updatedPosts,
              },
            };
          },
          {
            revalidate: false,
          }
        );
      } catch (error) {
        throw new Error('Error viewing post');
      }
    },
    [mutatePosts]
  );

  return {
    studentSocietyId: student_society_id,
    studentSociety,
    isCandidatePage,
    isRecruiterPage,
    isManagerPage,
    societyName,
    subscribers_require_approval,
    isNewPostModalOpen,
    openNewPostModal,
    closeNewPostModal,
    isSettingsModalOpen,
    openSettingsModal,
    closeSettingsModal,
    showPendingPosts,
    openPendingPosts,
    closePendingPosts,
    posts: approvedPosts,
    pendingPosts,
    isPostsLoading: isPostsFetched.current ? false : isPostsLoading,
    isPostCreationLoading,
    addPost,
    deletePost,
    canPostOptions,
    canReplyOptions,
    showMediaUploadsFromOptions,
    initializedPoll,
    initializePoll,
    deinitializePoll,
    onApprovePost,
    onDeclinePost,
    onLikePost,
    onUnlikePost,
    onCopyPostURL,
    societyDiscussionsSettings,
    changeSocietyDiscussionSettings,
    mediaAttachments,
    documentAttachments,
    answerOnPoll,
    openLoginPopupForCandidate,
    showAdminApprovalInfo,
    clearAdminApprovalInfo,
    showNoDiscussionInfo,
    clearNoDiscussionInfo,
    isSocietyMember,
    onMessageUser,
    postViewedRef,
    onPostView,
    scrollIntoViewRef,
    isManagerUser: !isCandidatePage && isManager,
    isRecruiterUser: isRecruiterPage && isRecruiter,
    currentUserId: isCandidatePage ? (isCandidate ? user?.id : null) : user?.id,
    likeLoadingPostId,
    queryDiscussionId,
  };
}

export type UseDiscussionsReturnType = ReturnType<typeof useDiscussionsInternalHook>;
