import { formatDistanceToNowStrict } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';

type TimeAgoProps = {
  timestamp: string;
};

enum TimeAgoUnit {
  SECOND = 1000,
  MINUTE = 60000,
  HOUR = 3600000,
  DAY = 86400000,
}

function calculateTimeAgo(timestamp: string) {
  const now = new Date().getTime();
  const milliSecondsAgo = now - new Date(timestamp).getTime();
  return milliSecondsAgo;
}

const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}mo',
  xMonths: '{{count}}mo',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

const TimeAgo = ({ timestamp }: TimeAgoProps) => {
  const intervalId = useRef<ReturnType<typeof setInterval>>();
  const [timeAgo, setTimeAgo] = useState(calculateTimeAgo(timestamp));
  const [hasIntervalTimeChanged, changeIntervalTime] = useState(false);

  useEffect(() => {
    const updateInterval = () => {
      const milliSecondsAgo = calculateTimeAgo(timestamp);

      if (milliSecondsAgo < TimeAgoUnit.MINUTE) {
        intervalId.current = setInterval(() => {
          const updatedMilliSecondsAgo = calculateTimeAgo(timestamp);
          if (updatedMilliSecondsAgo > TimeAgoUnit.MINUTE) {
            changeIntervalTime((prev) => !prev);
          }
          setTimeAgo(updatedMilliSecondsAgo);
        }, TimeAgoUnit.SECOND);
      } else {
        intervalId.current = setInterval(() => {
          const updatedMilliSecondsAgo = calculateTimeAgo(timestamp);
          setTimeAgo(updatedMilliSecondsAgo);
        }, TimeAgoUnit.MINUTE);
      }
    };

    updateInterval();

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, [timestamp, hasIntervalTimeChanged]);

  const timeAgoLabel = useMemo(() => {
    return formatDistanceToNowStrict(new Date(timestamp), {
      locale: {
        formatDistance: (token, count) => {
          // @ts-ignore
          const result = formatDistanceLocale[token].replace('{{count}}', count);
          return result;
        },
      },
    });
  }, [timestamp, timeAgo]);

  return <>{timeAgoLabel}</>;
};

export default TimeAgo;
