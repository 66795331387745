import React, { ReactElement, useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { capitalize } from 'lodash';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { IconButton, Tooltip } from 'components';
import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import { ButtonV3, DropdownV3 } from 'components/ComponentV2';
import Modal from 'components/Modal';
import { PostImageUploader, PostDocumentUploader, PostPollCreator, CancelableBannerInfo } from 'components/discussions';
import { AutoHeightAdjustableInput } from 'components/AutoHeightAdjustableInput';
import { useResponsive } from 'hooks/useResponsive';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { useAuth } from 'lib/providers/AuthProvider';
import {
  TDocumentPostPayload,
  TMediaPostPayload,
  TPollPostPayload,
  TPostPayload,
  TPostType,
  TTextPostPayload,
  TUserableType,
} from 'lib/models/discussion';
import { TPostParticipant } from 'lib/models/student-society';

import styles from './NewPostModal.module.scss';

const NewPostModal: React.FC<{ className?: string; containerClassName?: string }> = ({ className, containerClassName }): ReactElement => {
  const [t] = useTranslation('discussions');
  const { user, basicParticipant } = useAuth();
  const {
    isNewPostModalOpen,
    closeNewPostModal,
    addPost,
    canReplyOptions,
    societyDiscussionsSettings,
    initializedPoll,
    deinitializePoll,
    isCandidatePage,
    showAdminApprovalInfo,
    clearAdminApprovalInfo,
    societyName,
    isManagerUser,
  } = useDiscussions();
  const inputRef = useRef<HTMLDivElement>(null);
  const [postType, setPostType] = useState<TPostPayload['type']>(TPostType.TextPost);
  const [postContent, setPostContent] = useState<TPostPayload['content']>('');
  const [postImages, setPostImages] = useState<TMediaPostPayload['media']>([]);
  const [postDocuments, setPostDocuments] = useState<TDocumentPostPayload['documents']>([]);
  const [postPollOptions, setPostPollOptions] = useState<TPollPostPayload['poll_answer_options_attributes']>([]);
  const [canBeCommentedBy, setCanBeCommentedBy] = useState<TPostPayload['who_can_reply']>(societyDiscussionsSettings.who_can_reply_to_posts);
  const [isPostSubmitting, setPostSubmittings] = useState(false);
  const [vh, setVh] = useState(typeof window === 'undefined' ? 0 : window.visualViewport?.height);
  const screens = useResponsive();
  const modalRefContent = useRef<HTMLDivElement>(null);

  const onChangeWhoCanReplyToPost = useCallback((value: string) => {
    setCanBeCommentedBy(value as TPostParticipant);
  }, []);

  useEffect(() => {
    if (isNewPostModalOpen) {
      inputRef.current?.focus();
    }
  }, [inputRef, isNewPostModalOpen]);

  useEffect(() => {
    if (!isNewPostModalOpen || !modalRefContent.current) {
      return;
    }

    disableBodyScroll(modalRefContent.current);

    return clearAllBodyScrollLocks;
  }, [modalRefContent, isNewPostModalOpen]);

  const canSubmit = useMemo(() => {
    switch (postType) {
      case TPostType.TextPost:
        return postContent.trim().length > 0;
      case TPostType.MediaPost:
        return postImages.length > 0;
      case TPostType.DocumentPost:
        return postDocuments.length > 0;
      case TPostType.PollPost:
        return postContent.trim().length > 0 && postPollOptions.length > 0 && postPollOptions.every((option) => option.value.trim().length > 0);
    }
  }, [postContent, postImages, postPollOptions, postDocuments]);

  const onSubmit = useCallback(async () => {
    if (!user) return;

    setPostSubmittings(true);
    const basicPayload = {
      content: postContent,
      userable_type: capitalize(user.userable_type) as TUserableType,
      who_can_reply: canBeCommentedBy,
    };

    let postPayload: TTextPostPayload | TMediaPostPayload | TDocumentPostPayload | TPollPostPayload | null = null;

    switch (postType) {
      case TPostType.TextPost:
        postPayload = { ...basicPayload, type: TPostType.TextPost } as TTextPostPayload;
        break;
      case TPostType.MediaPost:
        postPayload = { ...basicPayload, type: TPostType.MediaPost, media: postImages } as TMediaPostPayload;
        break;
      case TPostType.DocumentPost:
        postPayload = { ...basicPayload, type: TPostType.DocumentPost, documents: postDocuments } as TDocumentPostPayload;
        break;
      case TPostType.PollPost:
        postPayload = { ...basicPayload, type: TPostType.PollPost, poll_answer_options_attributes: postPollOptions } as TPollPostPayload;
        break;
    }

    if (!postPayload) return;

    try {
      await addPost(postPayload);
      closeNewPostModal();
    } catch (error) {
      console.error('Error adding post', error);
    } finally {
      setPostSubmittings(false);
    }
  }, [user, postContent, postImages, postDocuments, postPollOptions, canBeCommentedBy, addPost, closeNewPostModal]);

  useEffect(() => {
    if (!isNewPostModalOpen) {
      setPostType(TPostType.TextPost);
      setPostContent('');
      setPostImages([]);
      setPostDocuments([]);
      setPostPollOptions([]);
    }
  }, [isNewPostModalOpen]);

  useEffect(() => {
    if (isNewPostModalOpen) {
      setCanBeCommentedBy(societyDiscussionsSettings.who_can_reply_to_posts);
    }
  }, [isNewPostModalOpen, societyDiscussionsSettings]);

  const onImageUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    setPostType(TPostType.MediaPost);
    setPostImages((prev) => [...prev, ...files]);
  }, []);

  const onImageRemove = useCallback(
    (index: number) => {
      if (postImages.length === 1) {
        setPostType(TPostType.TextPost);
      }
      setPostImages((prev) => {
        const updatedImages = [...prev];
        updatedImages.splice(index, 1);
        return updatedImages;
      });
    },
    [postImages, setPostType]
  );

  const onDocumentUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const [file] = files;
    setPostDocuments([file]);
    setPostType(TPostType.DocumentPost);
  }, []);

  const onDocumentRemove = useCallback(() => {
    setPostDocuments([]);
    setPostType(TPostType.TextPost);
  }, []);

  const initializePollOptions = useCallback(() => {
    setPostType(TPostType.PollPost);
    setPostPollOptions([{ value: '' }, { value: '' }]);
  }, []);

  const handleAddOption = useCallback(() => {
    setPostPollOptions((prev) => [...prev, { value: '' }]);
  }, []);

  const updatePollOption = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, pollIndex: number) => {
      setPostPollOptions((prev) => {
        const updatedOptions = [...prev];
        updatedOptions[pollIndex] = { value: event.target.value };
        return updatedOptions;
      });
    },
    [setPostPollOptions]
  );

  const removeOption = useCallback((pollIndex: number) => {
    setPostPollOptions((prev) => {
      const updatedOptions = [...prev];
      updatedOptions.splice(pollIndex, 1);
      return updatedOptions;
    });
  }, []);

  const removePoll = useCallback(() => {
    setPostType(TPostType.TextPost);
    setPostPollOptions([]);
  }, []);

  const hasNoAdditionalAttachment = useMemo(() => {
    return postImages.length === 0 && postPollOptions.length === 0 && postDocuments.length === 0;
  }, [postImages, postPollOptions, postDocuments]);

  useEffect(() => {
    if (initializedPoll) {
      initializePollOptions();
      deinitializePoll();
    }
  }, [initializedPoll, initializePollOptions]);

  useEffect(() => {
    function listener() {
      const vh = window.visualViewport?.height;
      setVh(vh);
    }

    window.visualViewport?.addEventListener('resize', listener);

    return () => {
      window.visualViewport?.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <Modal
      isOpen={isNewPostModalOpen}
      onRequestClose={closeNewPostModal}
      closeOnOverlayClick={false}
      className={styles['modal-wrapper']}
      contentClassName={cx(styles['modal-container'], containerClassName)}
      style={screens.sm ? { height: vh } : {}}
    >
      <section className={cx(styles.modal, className)}>
        <header className={styles.header}>
          <p className={styles['title']}>{t('new-post-modal.title')}</p>
          <IconButton iconName="close" className={styles.close} size="auto" onClick={closeNewPostModal} />
        </header>
        {isCandidatePage && showAdminApprovalInfo ? (
          <div className={styles.banner}>
            <CancelableBannerInfo onCancel={clearAdminApprovalInfo}>
              {t('new-discussion.info-holder', { society_name: societyName })}
            </CancelableBannerInfo>
          </div>
        ) : null}
        <main className={styles['modal-content']} ref={modalRefContent}>
          <HuzzleUserAvatar user={basicParticipant} />
          <div className={styles['post-content']}>
            <div className={styles['post-text']}>
              <AutoHeightAdjustableInput
                value={postContent}
                onChange={setPostContent}
                placeholder={t('new-post-modal.placeholder')}
                className={styles['post-text-input']}
                ref={inputRef}
              />
            </div>
            <div
              className={cx(styles['post-attachment-actions'], {
                [styles['no-attachments']]: hasNoAdditionalAttachment,
              })}
            >
              {hasNoAdditionalAttachment || postImages.length ? (
                <PostImageUploader className={styles['icon-button']} images={postImages} onImageUpload={onImageUpload} onRemove={onImageRemove} />
              ) : null}
              {(isManagerUser && hasNoAdditionalAttachment) || postDocuments.length ? (
                <PostDocumentUploader
                  className={cx(styles['icon-button'], styles['document-uploader'])}
                  documents={postDocuments}
                  onDocumentUpload={onDocumentUpload}
                  onRemove={onDocumentRemove}
                />
              ) : null}
              {hasNoAdditionalAttachment || postPollOptions.length ? (
                <PostPollCreator
                  className={styles['icon-button']}
                  pollOptions={postPollOptions}
                  initializePollOptions={initializePollOptions}
                  handleAddOption={handleAddOption}
                  updatePollOption={updatePollOption}
                  removePoll={removePoll}
                  removeOption={removeOption}
                />
              ) : null}
            </div>
          </div>
        </main>
        <footer className={styles.footer}>
          {isManagerUser ? (
            <Tooltip
              content={t('new-post-modal.who-can-reply-tooltip', {
                audience: t(`post-participant.${societyDiscussionsSettings.who_can_reply_to_posts}`),
              })}
              position="top"
              xAlign="center"
              color="dark"
              className={cx(styles.tooltip, styles['who-can-reply-tooltip'])}
              childrenClassName={styles['who-can-reply-tooltip-child']}
              hasWhitespaceNoWrap={false}
              disabled={canBeCommentedBy !== societyDiscussionsSettings.who_can_reply_to_posts}
              isRounded
            >
              <DropdownV3
                className={styles.dropdown}
                dropdownBtnClassName={styles['dropdown-button']}
                dropdownClassName={styles['dropdown-options']}
                isValid
                size="small"
                variant="secondary"
                label=""
                options={canReplyOptions}
                onChange={onChangeWhoCanReplyToPost}
                value={canBeCommentedBy}
                selectedValueLabel={t(`new-post-modal.who-can-replay-selected.${canBeCommentedBy}`)}
              />
            </Tooltip>
          ) : (
            <span></span>
          )}
          <ButtonV3 onClick={onSubmit} disabled={!canSubmit} isLoading={isPostSubmitting} className={styles['post-button']}>
            {t('new-post-modal.post')}
          </ButtonV3>
        </footer>
      </section>
    </Modal>
  );
};

export default NewPostModal;

NewPostModal.displayName = 'NewPostModal';
