import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { IconButton } from 'components';
import Modal from 'components/Modal';
import { TMediaPost } from 'lib/models/discussion';

import styles from './PostFullScreenImages.module.scss';

type PostFullScreenImagesProps = {
  images: TMediaPost['media'];
  startImageIndex: number;
  onClose: () => void;
};

const PostFullScreenImages = ({ images, startImageIndex, onClose }: PostFullScreenImagesProps) => {
  const [activeIndex, setActiveIndex] = useState(startImageIndex);

  const onNavigationButtonPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      setActiveIndex((activeIdx) => (activeIdx >= 1 ? activeIdx - 1 : activeIdx));
    } else if (event.key === 'ArrowRight') {
      setActiveIndex((activeIdx) => (activeIdx < images.length - 1 ? activeIdx + 1 : activeIdx));
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onNavigationButtonPress, false);
    return () => document.removeEventListener('keydown', onNavigationButtonPress, false);
  }, [onNavigationButtonPress]);

  return (
    <Modal isOpen={true} closeOnOverlayClick={true} closeOnESCPress={true} contentClassName={styles.modal} onRequestClose={onClose}>
      <IconButton iconName="cancel" size="medium" className={cx(styles.action, styles.close)} onClick={onClose} />
      {activeIndex >= 1 && images.length > 0 && (
        <IconButton
          iconName="arrow-button-left"
          size="medium"
          className={cx(styles.action, styles.left)}
          onClick={() => setActiveIndex((index) => index - 1)}
        />
      )}
      {activeIndex < images.length - 1 && (
        <IconButton
          iconName="arrow-button-right"
          size="medium"
          className={cx(styles.action, styles.right)}
          onClick={() => setActiveIndex((index) => index + 1)}
        />
      )}
      {images.map((image, index) => (
        <img src={image.url} key={image.id} alt="" className={cx(styles['fullscreen-image'], { [styles.active]: activeIndex === index })} />
      ))}
    </Modal>
  );
};

PostFullScreenImages.displayName = 'PostFullScreenImages';

export default PostFullScreenImages;
