import React from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { useTranslation } from 'next-i18next';

import { IconButton } from 'components';
import Modal from 'components/Modal';
import { ButtonV3 } from 'components/ComponentV2';

import styles from './PostDeleteConfirmationModal.module.scss';

interface ModalInstanceProps {
  ownPost: boolean;
}

const ModalInstance: React.FC<ModalInstanceProps & InstanceProps<number>> = ({ isOpen, onResolve, onReject, ownPost }) => {
  const [t] = useTranslation('discussions');
  return (
    <Modal isOpen={isOpen} onRequestClose={onReject} contentClassName={styles.container} className={styles.confirmationModal}>
      <IconButton size="xsmall" iconName="close" className={styles['cancel-button']} onClick={() => onReject()} />
      <div className={styles.content}>
        <header className={styles.header}>
          <p className={styles.title}>{t('delete-post-modal.title')}</p>
        </header>
        <div className={styles['description-container']}>
          <p className={styles.description}>{ownPost ? t('delete-post-modal.description-11') : t('delete-post-modal.description-12')}</p>
          <hr className={styles.divider} />
          <p className={styles.description}>{t('delete-post-modal.description-21')}</p>
        </div>
      </div>
      <footer className={styles.footer}>
        <ButtonV3 color="secondary" onClick={() => onReject()} className={styles['action-button']}>
          {t('delete-post-modal.cancel-action')}
        </ButtonV3>
        <ButtonV3 color="danger" onClick={() => onResolve()} className={styles['action-button']}>
          {t('delete-post-modal.delete-action')}
        </ButtonV3>
      </footer>
    </Modal>
  );
};

export const postDeleteConfirmationModalInstance = create(ModalInstance);
