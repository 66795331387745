import React from 'react';

import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './StartPostReply.module.scss';

type StartPostReplyProps = {
  onStartReply: () => void;
  placeholder: string;
};

const StartPostReply: React.FC<StartPostReplyProps> = ({ onStartReply, placeholder }) => {
  const { basicParticipant } = useAuth();

  return (
    <div className={styles.container}>
      <HuzzleUserAvatar user={basicParticipant} />
      <div className={styles.content}>
        <button className={styles.input} onClick={onStartReply}>
          {placeholder}
        </button>
      </div>
    </div>
  );
};

StartPostReply.displayName = 'StartPostReply';

export default StartPostReply;
