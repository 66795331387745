import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  UseDiscussionsReturnType,
  useDiscussionsInternalHook,
} from 'hooks/useDiscussions/useDiscussionsInternal';
import { TPostParticipant } from 'lib/models/student-society';
import {
  TBasicStudentSociety,
  TDocumentPost,
  TMediaPost,
  TPost,
} from 'lib/models/discussion';

const initialContext: UseDiscussionsReturnType = {
  studentSocietyId: '',
  studentSociety: {
    id: '',
    name: '',
    short_slug: '',
    slug: '',
    icon_url: '',
    is_verified: false,
    is_huzzle_verified: false,
    subscribers_require_approval: false,
    who_can_post: TPostParticipant.MEMBERS_ONLY,
    who_can_reply_to_posts: TPostParticipant.MEMBERS_ONLY,
    show_media_uploads_from_posts: TPostParticipant.TEAM_ONLY,
    is_post_approval_required: false,
  },
  isCandidatePage: false,
  isRecruiterPage: false,
  isManagerPage: false,
  societyName: '',
  isNewPostModalOpen: false,
  openLoginPopupForCandidate: () => new Promise(() => {}),
  openNewPostModal: Promise.resolve,
  closeNewPostModal: noop,
  isSettingsModalOpen: false,
  openSettingsModal: noop,
  closeSettingsModal: noop,
  posts: [],
  pendingPosts: [],
  isPostsLoading: false,
  addPost: Promise.resolve,
  deletePost: Promise.resolve,
  canPostOptions: [],
  canReplyOptions: [],
  showMediaUploadsFromOptions: [],
  initializedPoll: false,
  initializePoll: noop,
  deinitializePoll: noop,
  showPendingPosts: false,
  openPendingPosts: noop,
  closePendingPosts: noop,
  onApprovePost: Promise.resolve,
  onDeclinePost: Promise.resolve,
  isPostCreationLoading: false,
  onLikePost: Promise.resolve,
  onUnlikePost: Promise.resolve,
  onCopyPostURL: Promise.resolve,
  societyDiscussionsSettings: {
    who_can_post: TPostParticipant.MEMBERS_ONLY,
    who_can_reply_to_posts: TPostParticipant.MEMBERS_ONLY,
    show_media_uploads_from_posts: TPostParticipant.TEAM_ONLY,
    is_post_approval_required: true,
  },
  changeSocietyDiscussionSettings: Promise.resolve,
  mediaAttachments: [],
  documentAttachments: [],
  answerOnPoll: Promise.resolve,
  showAdminApprovalInfo: false,
  clearAdminApprovalInfo: noop,
  showNoDiscussionInfo: false,
  clearNoDiscussionInfo: noop,
  isSocietyMember: false,
  onMessageUser: Promise.resolve,
  subscribers_require_approval: false,
  postViewedRef: { current: {} },
  onPostView: Promise.resolve,
  scrollIntoViewRef: { current: false },
  isManagerUser: false,
  isRecruiterUser: false,
  currentUserId: '',
  likeLoadingPostId: null,
  queryDiscussionId: '',
};

const DiscussionsContext =
  createContext<UseDiscussionsReturnType>(initialContext);

export const useDiscussions = () => useContext(DiscussionsContext);

type DiscussionsProviderProps = {
  children: React.ReactNode;
  studentSociety: TBasicStudentSociety;
  isCandidatePage?: boolean;
  isRecruiterPage?: boolean;
  isManagerPage?: boolean;
  isSocietyMember?: boolean;
  skipAttachments?: boolean;
  initialPosts?: Array<TPost>;
  initialMediaAttachments?: TMediaPost['media'];
  initialDocumentAttachments?: TDocumentPost['documents'];
};

export const DiscussionsProvider: React.FC<DiscussionsProviderProps> = ({
  children,
  studentSociety,
  isCandidatePage,
  isRecruiterPage,
  isManagerPage,
  isSocietyMember,
  skipAttachments,
  initialPosts,
  initialMediaAttachments,
  initialDocumentAttachments,
}) => {
  const value = useDiscussionsInternalHook({
    studentSociety,
    isCandidatePage,
    isSocietyMember,
    isRecruiterPage,
    isManagerPage,
    skipAttachments,
    initialPosts,
    initialMediaAttachments,
    initialDocumentAttachments,
  });

  return (
    <DiscussionsContext.Provider value={value}>
      {children}
    </DiscussionsContext.Provider>
  );
};
