import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { Icon } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';

import styles from './PendingPostsBanner.module.scss';

type PendingPostsBannerProps = {
  className?: string;
};

const PendingPostsBanner: React.FC<PendingPostsBannerProps> = ({ className }) => {
  const [t] = useTranslation('discussions');
  const { openPendingPosts, pendingPosts } = useDiscussions();

  if (!pendingPosts.length) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.content}>
        <Icon iconName="icon_info-outline" className={styles.icon} size="auto" />
        <p>{t('post-approval.title', { count: pendingPosts.length })}</p>
      </div>
      <ButtonV3 startIcon="icon_check-list" className={styles.review} onClick={openPendingPosts}>
        {t('post-approval.review-posts')}
      </ButtonV3>
    </div>
  );
};

PendingPostsBanner.displayName = 'PendingPostsBanner';

export default PendingPostsBanner;
