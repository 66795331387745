import React from 'react';
import cx from 'classnames';

import { IconButton } from 'components';
import { PostDocument } from 'components/discussions';
import { TDocumentPostPayload } from 'lib/models/discussion';

import styles from './PostDocumentUploader.module.scss';

type PostDocumentUploaderProps = {
  className?: string;
  documents: TDocumentPostPayload['documents'];
  onDocumentUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemove: () => void;
};

const PostDocumentUploader: React.FC<PostDocumentUploaderProps> = ({ className, documents, onDocumentUpload, onRemove }) => {
  return (
    <div className={cx(styles.container, { [styles['full-width']]: documents.length > 0 })}>
      {documents.length === 0 ? (
        <IconButton iconName="icon_document-2" size="auto" className={className} iconClassName={styles.icon} htmlFor="upload-post-document" />
      ) : null}
      <input type="file" accept=".pdf, .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx" id="upload-post-document" onChange={onDocumentUpload} hidden />
      {documents.length > 0 ? (
        <div className={styles['post-documents']}>
          {documents.map((document) => (
            <PostDocument key={document.lastModified} filename={document.name} onRemove={onRemove} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

PostDocumentUploader.displayName = 'PostDocumentUploader';

export default PostDocumentUploader;
