import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { IconButton } from 'components';
import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import ActionMenu from 'components/ActionMenu';
import { PostImages, PostPollOptions, TimeAgo } from 'components/discussions';
import { MessageContent } from 'components/ComponentV2';
import { TPost, TPostType, TUserableType } from 'lib/models/discussion';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { getBasicPostParticipant } from 'lib/utils/discussion';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './PostReply.module.scss';

type PostReplyProps = {
  postReply: TPost;
  postId: string;
  className?: string;
};

const PostReply: React.FC<PostReplyProps> = ({ postReply, postId: parentPostId, className = '' }) => {
  const [tCommon] = useTranslation('common');
  const [t] = useTranslation('discussions');
  const { recruiterCompany } = useAuth();
  const postedByUser = useMemo(() => getBasicPostParticipant(postReply), [postReply]);
  const { onLikePost, onUnlikePost, deletePost, isCandidatePage, isRecruiterUser, isManagerUser, currentUserId, likeLoadingPostId, onMessageUser } =
    useDiscussions();

  const actionOptions = useMemo(() => {
    const options = [];

    if (postReply.user.userable_type === TUserableType.Manager && isManagerUser) {
      options.push({
        id: '1',
        label: tCommon('message'),
        danger: false,
        onClick: () => onMessageUser(postReply),
      });
    }

    if (isManagerUser || postedByUser.id === currentUserId || (isRecruiterUser && recruiterCompany?.id === postedByUser.id)) {
      options.push({
        id: '2',
        label: tCommon('delete'),
        danger: true,
        onClick: () => deletePost({ postId: postReply.id, parentPostId }),
      });
    }

    return options;
  }, [tCommon, isCandidatePage, postedByUser, isRecruiterUser, isManagerUser, postReply, currentUserId, onMessageUser, deletePost]);

  const { id: postId, post_type, content, created_at, likes } = postReply;
  const likes_count = likes.length;
  const likeInstance = useMemo(() => likes.find((like) => like.user_id === currentUserId), [likes, currentUserId]);

  return (
    <section className={cx(styles.container, className)}>
      <div className={styles['avatar-container']}>
        <HuzzleUserAvatar user={postedByUser} />
      </div>
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles['name-and-duration']}>
            <p className={styles.name}>{postedByUser.name}</p>
            <p className={styles.duration}>
              <TimeAgo timestamp={created_at} />
            </p>
          </div>
          {actionOptions.length > 0 ? (
            <ActionMenu options={actionOptions} triggerButtonClassName={styles['action-menu-button']} listClassName={styles['action-menu-list']} />
          ) : null}
        </header>
        <div className={styles['post-content']}>
          {content ? <MessageContent content={content} /> : null}
          {post_type === TPostType.MediaPost ? <PostImages images={postReply.media} className={styles.images} /> : null}
          {post_type === TPostType.PollPost ? (
            <div className={cx(styles['poll-options'], { [styles['has-content']]: content })}>
              <PostPollOptions pollOptions={postReply.poll_answer_options} is_post_approved={true} postId={postId} />
            </div>
          ) : null}
        </div>
        <div className={styles['actions-stats-container']}>
          <IconButton
            iconName={likeInstance ? 'heart-filled' : 'heart'}
            size="auto"
            className={cx(styles['icon-button'], { [styles.disabled]: likeLoadingPostId === postId, [styles['icon-filled']]: likeInstance })}
            iconClassName={styles.icon}
            onClick={
              likeInstance
                ? likeLoadingPostId
                  ? undefined
                  : () => onUnlikePost({ postId: postId, likeId: likeInstance.id })
                : () => onLikePost({ postId })
            }
          />
          {likes_count ? (
            <span className={styles['stat']}>
              {t('post.likes_count', {
                count: likes_count,
              })}
            </span>
          ) : null}
        </div>
      </div>
    </section>
  );
};

PostReply.displayName = 'PostReply';

export default PostReply;
