import cx from 'classnames';

import IconButton from 'components/IconButton';
import Icon from 'components/Icon';

import styles from './CancelableBannerInfo.module.scss';

type CancelableBannerInfoProps = {
  children: React.ReactNode;
  onCancel: () => void;
  className?: string;
};

const CancelableBannerInfo: React.FC<CancelableBannerInfoProps> = ({ children, onCancel, className }) => {
  return (
    <div className={cx(styles['info-container'], className)}>
      <div className={styles['title-icon']}>
        <Icon iconName="info" size="large" className={styles['info-icon']} />
        {children}
      </div>
      <IconButton iconName="close" size="small" className={styles['icon-button']} iconClassName={styles.icon} onClick={onCancel} />
    </div>
  );
};

CancelableBannerInfo.displayName = 'CancelableBannerInfo';

export default CancelableBannerInfo;
