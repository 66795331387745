import { useCallback, useEffect, useState } from 'react';

import { useDiscussions } from 'lib/providers/DiscussionsProvider';

import { TPost, TPostPayload } from 'lib/models/discussion';

export function useDiscussionInternalHook() {
  const [post, setPost] = useState<TPost | null>(null);
  const { addPost, posts } = useDiscussions();
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);

  const setCurrentPost = useCallback((post: TPost) => {
    setPost(post);
    setIsPostModalOpen(true);
  }, []);

  const clearCurrentPost = useCallback(() => {
    setPost(null);
    setIsPostModalOpen(false);
  }, []);

  const setCurrentThread = useCallback((post: TPost) => {
    setPost(post);
  }, []);

  const clearCurrentThread = useCallback(() => {
    setPost(null);
  }, []);

  const openPostModal = useCallback(() => {
    setIsPostModalOpen(true);
  }, []);

  const closePostModal = useCallback(() => {
    setIsPostModalOpen(false);
  }, []);

  useEffect(() => {
    if (post) {
      const postInPosts = posts.find((p) => p.id === post.id);
      if (postInPosts) {
        setPost(postInPosts);
      } else {
        setPost(null);
      }
    }
  }, [posts]);

  const addPostReply = useCallback(async (reply: TPostPayload) => {
    try {
      await addPost(reply);
    } catch (error) {
      console.error('Error adding post reply', error);
    }
  }, []);

  return {
    post,
    isPostModalOpen,
    setCurrentPost,
    clearCurrentPost,
    setCurrentThread,
    clearCurrentThread,
    addPostReply,
    openPostModal,
    closePostModal,
  };
}

export type UseDiscussionReturnType = ReturnType<typeof useDiscussionInternalHook>;
