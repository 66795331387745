import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { Icon, IconName } from 'components';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { downloadFile, getDocumentExtension } from 'lib/utils/discussion';

import styles from './DiscussionsDocs.module.scss';

const DiscussionsDocs = ({ docSize = 'large' }: { docSize?: 'small' | 'large' }) => {
  const [t] = useTranslation('discussions');
  const { documentAttachments } = useDiscussions();

  if (!documentAttachments) {
    return null;
  }

  if (documentAttachments.length === 0) {
    return <p className={styles['no-docs']}>{t('no-content.docs.title')}</p>;
  }

  return (
    <div className={styles.container}>
      {documentAttachments.map(({ id, file_name, url }) => {
        const fileExtension = getDocumentExtension(file_name);
        return (
          <div className={cx(styles['post-doc'], styles[docSize])} key={id} onClick={() => downloadFile({ filename: file_name, url })}>
            {fileExtension ? <Icon iconName={`file_type_${fileExtension}` as IconName} size="xxxlarge" /> : null}
            <div className={styles.name}>{file_name}</div>
          </div>
        );
      })}
    </div>
  );
};

DiscussionsDocs.displayName = 'DiscussionsDocs';

export default DiscussionsDocs;
