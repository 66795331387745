import { serialize } from 'object-to-formdata';

import { TPost, TPostLike, TPostPayload, TPostState, TPostType, TSocietyDiscussionsSettings, TUserableType } from 'lib/models/discussion';
import { apiInstance } from 'lib/utils/axios';
import { StudentSocietyResponse } from 'lib/models/student-society';

export async function updateStudentSociety({ settings }: { settings: TSocietyDiscussionsSettings }) {
  const path = `/api/v1/student_society`;
  const { data } = await apiInstance.patch<StudentSocietyResponse>(path, { student_society: settings });
  return data;
}

export function createSocietyPost({ student_society_id, payload }: { student_society_id: string; payload: TPostPayload }) {
  const path = `/api/v1/student_societies/${student_society_id}/posts`;
  const body = { post: payload };

  switch (payload.type) {
    case TPostType.TextPost:
    case TPostType.PollPost: {
      return apiInstance.post<TPost>(path, body);
    }
    case TPostType.MediaPost:
    case TPostType.DocumentPost: {
      const formData = serialize(body, { indices: false });
      return apiInstance.post<TPost>(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  }
}

export function deleteSocietyPost({ post_id }: { post_id: string }) {
  const path = `/api/v1/posts/${post_id}`;
  return apiInstance.delete(path);
}

export function likePost({ post_id, userable_type }: { post_id: string; userable_type: TUserableType }) {
  const path = `/api/v1/posts/${post_id}/likes`;
  const body = { like: { userable_type } };
  return apiInstance.post<TPostLike>(path, body);
}

export function unlikePost({ like_id }: { like_id: string }) {
  const path = `/api/v1/likes/${like_id}`;
  return apiInstance.delete(path);
}

export function approvePost({ post_id }: { post_id: string }) {
  const path = `/api/v1/posts/${post_id}`;
  return apiInstance.patch(path, { post: { state: TPostState.Approved } });
}

export function declinePost({ post_id }: { post_id: string }) {
  const path = `/api/v1/posts/${post_id}`;
  return apiInstance.patch(path, { post: { state: TPostState.Declined } });
}

export async function voteOnPollOption({
  post_id,
  poll_answer_option_id,
  userable_type,
}: {
  post_id: string;
  poll_answer_option_id: string;
  userable_type: TUserableType;
}) {
  const path = `/api/v1/posts/${post_id}/poll_answers`;
  const body = { poll_answer: { poll_answer_option_id, userable_type } };
  return apiInstance.post(path, body);
}

export function viewAllPosts({ post_id }: { post_id: string }) {
  const path = `/api/v1/candidate_transactions`;
  const body = {
    candidate_transaction: {
      trackable_id: post_id,
      trackable_type: 'Post',
      transaction_type: 'viewed_post',
    },
  };
  return apiInstance.post(path, body);
}
