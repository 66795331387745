import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  UseDiscussionReturnType,
  useDiscussionInternalHook,
} from 'hooks/useDiscussion/useDiscussionInternal';

const initialContext: UseDiscussionReturnType = {
  post: null,
  isPostModalOpen: false,
  setCurrentPost: noop,
  clearCurrentPost: noop,
  setCurrentThread: noop,
  clearCurrentThread: noop,
  addPostReply: Promise.resolve,
  openPostModal: noop,
  closePostModal: noop,
};

const DiscussionContext =
  createContext<UseDiscussionReturnType>(initialContext);

export const useDiscussion = () => useContext(DiscussionContext);

export const DiscussionProvider: React.FC = ({ children }) => {
  const value = useDiscussionInternalHook();

  return (
    <DiscussionContext.Provider value={value}>
      {children}
    </DiscussionContext.Provider>
  );
};
