import { useCallback, useRef, useState } from 'react';
import cx from 'classnames';

import { PostFullScreenImages } from 'components/discussions';
import { TMediaPost } from 'lib/models/discussion';
import { isImageLandscape } from 'lib/utils/discussion';

import styles from './PostImages.module.scss';

const MAX_IMAGES = 4;

const PostImages = ({ images, className }: { images: TMediaPost['media']; className?: string }) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState<number | null>(null);

  if (images.length === 0) {
    return null;
  }

  const first4Images = images.slice(0, MAX_IMAGES);

  const closeImagesModal = useCallback(() => {
    setFullScreenImageIndex(null);
  }, []);

  return (
    <>
      <div
        className={cx(styles.container, className, {
          [styles.multiple]: images.length > 1,
          [styles.single]: images.length === 1,
        })}
      >
        {first4Images.map((image, index) => {
          const isLandscape = isImageLandscape(imageRef.current);
          const lastImage = index === MAX_IMAGES - 1 && images.length > MAX_IMAGES;
          return (
            <div key={image.id} className={cx(styles['image-container'], { [styles.last]: lastImage })}>
              <img
                ref={imageRef}
                key={image.id}
                src={image.url}
                alt="post"
                loading="lazy"
                onClick={() => setFullScreenImageIndex(index)}
                className={cx(styles['image'], {
                  [styles['is-landscape']]: isLandscape === true,
                  [styles['is-portrait']]: isLandscape === false,
                })}
              />
              {lastImage ? <span className={styles['additional-images']}>{`+${images.length - MAX_IMAGES}`}</span> : null}
            </div>
          );
        })}
      </div>
      {fullScreenImageIndex !== null && <PostFullScreenImages startImageIndex={fullScreenImageIndex} images={images} onClose={closeImagesModal} />}
    </>
  );
};

PostImages.displayName = 'PostImages';

export default PostImages;
