import { useMemo } from 'react';
import cx from 'classnames';

import { noop } from 'lodash';
import Icon, { IconName } from 'components/Icon';
import IconButton from 'components/IconButton';
import { downloadFile, getDocumentExtension } from 'lib/utils/discussion';

import styles from './PostDocument.module.scss';

type PostDocumentProps = {
  filename: string;
  url?: string;
  onRemove?: () => void;
  className?: string;
  fullWidth?: boolean;
};

const PostDocument = ({ filename, url, onRemove, className, fullWidth = false }: PostDocumentProps) => {
  const fileExtension = useMemo(() => {
    return getDocumentExtension(filename);
  }, [filename]);

  const onDownload = () => {
    if (!url) return;
    downloadFile({ url, filename });
  };

  return (
    <div
      className={cx(styles['post-document'], className, {
        [styles['full-width']]: fullWidth,
        [styles['downloadable']]: url,
      })}
      onClick={url ? onDownload : noop}
    >
      <div className={styles['file-details']}>
        {fileExtension ? <Icon iconName={`file_type_${fileExtension}` as IconName} size="xxxlarge" /> : null}
        <div className={styles.name}>{filename}</div>
      </div>
      {onRemove ? <IconButton iconName="close" size="auto" className={styles.remove} onClick={onRemove} /> : null}
    </div>
  );
};

PostDocument.displayName = 'PostDocument';

export default PostDocument;
