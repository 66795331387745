import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CancelableBannerInfo, Post, StartDiscussion } from 'components/discussions';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { TPostParticipant } from 'lib/models/student-society';

import styles from './DiscussionsPosts.module.scss';

const DiscussionsPosts = () => {
  const [t] = useTranslation('discussions');
  const { posts, showNoDiscussionInfo, clearNoDiscussionInfo, societyName, societyDiscussionsSettings, isSocietyMember, queryDiscussionId } =
    useDiscussions();

  const canPost = useMemo(() => {
    switch (societyDiscussionsSettings.who_can_post) {
      case TPostParticipant.ANYONE:
        return true;
      case TPostParticipant.TEAM_ONLY:
        return false;
      case TPostParticipant.MEMBERS_ONLY:
        return isSocietyMember;
      default:
        return false;
    }
  }, [societyDiscussionsSettings, isSocietyMember]);

  return (
    <>
      {showNoDiscussionInfo ? (
        canPost ? (
          <CancelableBannerInfo onCancel={clearNoDiscussionInfo}>
            {t('no-content.discussion-banner-content', {
              society_name: societyName,
            })}
          </CancelableBannerInfo>
        ) : (
          <CancelableBannerInfo onCancel={clearNoDiscussionInfo}>
            {t('no-content.discussion-banner-content-cannot-post', {
              society_name: societyName,
            })}
          </CancelableBannerInfo>
        )
      ) : null}
      {canPost ? (
        <StartDiscussion
          className={styles['start-discussion']}
          placeholder={t('post-to-society.placeholder', {
            society_name: societyName,
          })}
        />
      ) : null}
      {posts.map((post) => (
        <Post key={post.id} post={post} className={styles.post} scrollToPost={queryDiscussionId === post.id} />
      ))}
    </>
  );
};

DiscussionsPosts.displayName = 'DiscussionsPosts';

export default DiscussionsPosts;
