import React, { ReactElement, useCallback, forwardRef, useEffect } from 'react';
import cx from 'classnames';

import styles from './AutoHeightAdjustableInput.module.scss';

type AutoHeightAdjustableInputProps = {
  value: string;
  onChange: (value: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: () => void;
  placeholder: string;
  className?: string;
  acceptInnerHtml?: boolean;
};

const AutoHeightAdjustableInput = forwardRef<
  HTMLDivElement,
  AutoHeightAdjustableInputProps
>(
  (
    {
      value,
      onChange,
      onKeyPress,
      onFocus,
      placeholder,
      acceptInnerHtml = true,
      className = '',
    },
    ref
  ): ReactElement => {
    const onTextChange = useCallback(
      (event: React.ChangeEvent<HTMLDivElement>) => {
        const value = acceptInnerHtml
          ? event.currentTarget.innerHTML
          : event.currentTarget.innerText;
        if (value) {
          onChange(value);
        } else {
          onChange('');
        }
      },
      [acceptInnerHtml]
    );

    useEffect(() => {
      function onPaste(event: ClipboardEvent) {
        // @ts-ignore
        if (document.execCommand) {
          event.preventDefault();
          if (!event.clipboardData) return;

          const text = event.clipboardData.getData('text/plain');
          // @ts-ignore
          document.execCommand('insertText', false, text);
        }
      }

      // @ts-ignore
      ref?.current?.addEventListener('paste', onPaste);

      return () => {
        // @ts-ignore
        ref?.current?.removeEventListener('paste', onPaste);
      };
    }, [ref]);

    return (
      <div
        ref={ref}
        role="textbox"
        contentEditable
        onInput={onTextChange}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        className={cx(styles.input, className)}
        placeholder={value ? '' : placeholder}
      ></div>
    );
  }
);

export default AutoHeightAdjustableInput;

AutoHeightAdjustableInput.displayName = 'AutoHeightAdjustableInput';
