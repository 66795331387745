import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { Icon, IconButton } from 'components';
import { Toggle } from 'components/form';
import Modal from 'components/Modal';
import { ButtonV3, DropdownV3 } from 'components/ComponentV2';
import Tooltip from 'components/Tooltip';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { TPostParticipant } from 'lib/models/student-society';
import { TSocietyDiscussionsSettings } from 'lib/models/discussion';

import styles from './DiscussionsSettingsModal.module.scss';

const DiscussionsSettingsModal: React.FC<{ className?: string; containerClassName?: string }> = ({ className, containerClassName }) => {
  const [t] = useTranslation('discussions');
  const {
    isSettingsModalOpen,
    closeSettingsModal,
    societyDiscussionsSettings,
    changeSocietyDiscussionSettings,
    canPostOptions,
    canReplyOptions,
    showMediaUploadsFromOptions,
  } = useDiscussions();

  const [whoCanPost, setWhoCanPost] = useState<TSocietyDiscussionsSettings['who_can_post']>(societyDiscussionsSettings.who_can_post);
  const [isPostApprovalRequired, setIsPostApprovalRequired] = useState<TSocietyDiscussionsSettings['is_post_approval_required']>(
    societyDiscussionsSettings.is_post_approval_required
  );
  const [whoCanReply, setWhoCanReply] = useState<TSocietyDiscussionsSettings['who_can_reply_to_posts']>(
    societyDiscussionsSettings?.who_can_reply_to_posts
  );
  const [showMediaUploadsFrom, setShowMediaUploadsFrom] = useState<TSocietyDiscussionsSettings['show_media_uploads_from_posts']>(
    societyDiscussionsSettings.show_media_uploads_from_posts
  );
  const [isSettingsUpdating, setIsSettingsUpdating] = useState(false);

  const canSubmit =
    societyDiscussionsSettings.who_can_post !== whoCanPost ||
    societyDiscussionsSettings.is_post_approval_required !== isPostApprovalRequired ||
    societyDiscussionsSettings.who_can_reply_to_posts !== whoCanReply ||
    societyDiscussionsSettings.show_media_uploads_from_posts !== showMediaUploadsFrom;

  useEffect(() => {
    if (whoCanPost === TPostParticipant.TEAM_ONLY && isPostApprovalRequired) {
      setIsPostApprovalRequired(false);
    }
  }, [whoCanPost, isPostApprovalRequired, setIsPostApprovalRequired]);

  const onSubmit = useCallback(async () => {
    try {
      setIsSettingsUpdating(true);
      await changeSocietyDiscussionSettings({
        who_can_post: whoCanPost,
        is_post_approval_required: isPostApprovalRequired,
        who_can_reply_to_posts: whoCanReply,
        show_media_uploads_from_posts: showMediaUploadsFrom,
      });
      closeSettingsModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSettingsUpdating(false);
    }
  }, [whoCanPost, isPostApprovalRequired, whoCanReply, showMediaUploadsFrom]);

  useEffect(() => {
    if (isSettingsModalOpen) return;

    setWhoCanPost(societyDiscussionsSettings.who_can_post);
    setIsPostApprovalRequired(societyDiscussionsSettings.is_post_approval_required);
    setWhoCanReply(societyDiscussionsSettings.who_can_reply_to_posts);
    setShowMediaUploadsFrom(societyDiscussionsSettings.show_media_uploads_from_posts);
  }, [isSettingsModalOpen, societyDiscussionsSettings]);

  return (
    <Modal
      isOpen={isSettingsModalOpen}
      onRequestClose={closeSettingsModal}
      closeOnOverlayClick={true}
      contentClassName={cx(styles['modal-container'], containerClassName)}
    >
      <section className={cx(styles.modal, className)}>
        <header className={styles.header}>
          <p className={styles['title']}>{t('discussions-settings-modal.title')}</p>
          <IconButton iconName="close" className={styles.close} size="auto" onClick={closeSettingsModal} />
        </header>
        <main className={styles['modal-content']}>
          <DropdownV3
            className={styles.dropdown}
            isValid
            size="small"
            title={t('discussions-settings-modal.who-can-post')}
            label={t('discussions-settings-modal.who-can-post')}
            options={canPostOptions}
            value={whoCanPost}
            onChange={(value) => setWhoCanPost(value as TSocietyDiscussionsSettings['who_can_post'])}
            selectedValueLabel={t(`post-participant.${whoCanPost}`)}
          />
          <Tooltip
            content={t('discussions-settings-modal.require-post-approval-tooltip')}
            position="top"
            xAlign="left"
            color="dark"
            className={cx(styles.tooltip, styles['require-post-approval-tooltip'])}
            hasWhitespaceNoWrap={false}
            disabled={whoCanPost !== TPostParticipant.TEAM_ONLY}
            isRounded
          >
            <Toggle
              labelTitle={t('discussions-settings-modal.require-post-approval')}
              className={styles.toggle}
              disabled={whoCanPost === TPostParticipant.TEAM_ONLY}
              checked={isPostApprovalRequired}
              onChange={(event) => setIsPostApprovalRequired(event.target.checked)}
            >
              {t('discussions-settings-modal.require-post-approval')}
            </Toggle>
          </Tooltip>
          <DropdownV3
            className={styles.dropdown}
            isValid
            size="small"
            title={t('discussions-settings-modal.who-can-reply')}
            label={t('discussions-settings-modal.who-can-reply')}
            options={canReplyOptions}
            value={whoCanReply}
            onChange={(value) => setWhoCanReply(value as TSocietyDiscussionsSettings['who_can_reply_to_posts'])}
            selectedValueLabel={t(`post-participant.${whoCanReply}`)}
          />
          <DropdownV3
            className={styles.dropdown}
            isValid
            size="small"
            title={t('discussions-settings-modal.show-media-uploads-from')}
            label={t('discussions-settings-modal.show-media-uploads-from')}
            options={showMediaUploadsFromOptions}
            value={showMediaUploadsFrom}
            onChange={(value) => setShowMediaUploadsFrom(value as TSocietyDiscussionsSettings['show_media_uploads_from_posts'])}
            selectedValueLabel={t(`post-participant.${showMediaUploadsFrom}`)}
            infoToolTip={
              <Tooltip
                content={t('discussions-settings-modal.show-media-uploads-from-tooltip')}
                position="top"
                xAlign="center"
                color="dark"
                className={cx(styles.tooltip, styles['show-media-uploads-from'])}
                hasWhitespaceNoWrap={false}
                isRounded
              >
                <Icon iconName="icon_info" size="xsmall" className={styles['show-media-uploads-from-tooltip-icon']} />
              </Tooltip>
            }
          />
        </main>
        <footer className={styles['footer']}>
          <ButtonV3 onClick={onSubmit} disabled={!canSubmit || isSettingsUpdating} isLoading={isSettingsUpdating}>
            {t('discussions-settings-modal.submit')}
          </ButtonV3>
        </footer>
      </section>
    </Modal>
  );
};

DiscussionsSettingsModal.displayName = 'DiscussionsSettingsModal';

export default DiscussionsSettingsModal;
