import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { IconButton } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import { TMediaPostPayload } from 'lib/models/discussion';

import { isImageLandscape } from 'lib/utils/discussion';
import styles from './PostImageUploader.module.scss';

type PostImageUploaderProps = {
  className?: string;
  images: TMediaPostPayload['media'];
  onImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemove: (index: number) => void;
  renderImages?: boolean;
};

type PostImageProps = {
  imageSrc: string;
  remove: () => void;
};

const PostImage = ({ imageSrc, remove }: PostImageProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isLandscape, setIsLandscape] = useState<boolean | null>(null);

  const handleImageLoad = () => {
    const flag = isImageLandscape(imageRef.current);
    setIsLandscape(flag);
  };

  return (
    <div className={styles['post-image-container']}>
      <img
        className={cx(styles['post-image'], {
          [styles['is-landscape']]: isLandscape === true,
          [styles['is-portrait']]: isLandscape === false,
        })}
        ref={imageRef}
        src={imageSrc}
        onLoad={handleImageLoad}
      />
      <IconButton iconName="close" size="xsmall" className={styles.remove} onClick={remove} />
    </div>
  );
};

const PostImageUploader: React.FC<PostImageUploaderProps> = ({ className, images, renderImages = true, onImageUpload, onRemove }) => {
  const [t] = useTranslation('discussions');

  const postImagesElement = useMemo(() => {
    if (!renderImages) return null;
    return (
      <div
        className={cx(styles['post-images'], {
          [styles.multiple]: images.length > 1,
          [styles.single]: images.length === 1,
        })}
      >
        {images.map((image, imageIndex) => (
          <PostImage key={image.lastModified} imageSrc={URL.createObjectURL(image)} remove={() => onRemove(imageIndex)} />
        ))}
      </div>
    );
  }, [images, renderImages, onRemove]);

  return (
    <div
      className={cx(styles.container, {
        [styles['full-width']]: images.length > 0,
      })}
    >
      {images.length === 0 ? (
        <IconButton iconName="icon_image" size="auto" className={className} iconClassName={styles.icon} htmlFor="upload-post-image" />
      ) : null}
      <div className={styles['post-images-container']}>
        {postImagesElement}
        <input type="file" accept="image/*" id="upload-post-image" multiple onChange={onImageUpload} hidden />
        {images.length > 0 && (
          <ButtonV3 color="secondary" borderless className={styles['add-another']} startIcon="icon_image" htmlFor="upload-post-image">
            {t('new-post-modal.add-another')}
          </ButtonV3>
        )}
      </div>
    </div>
  );
};

PostImageUploader.displayName = 'PostImageUploader';

export default PostImageUploader;
