import React, { ReactElement, useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { capitalize } from 'lodash';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { IconButton } from 'components';
import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import { ButtonV3 } from 'components/ComponentV2';
import Modal from 'components/Modal';
import { PostImageUploader, PostPollCreator, Post } from 'components/discussions';
import { AutoHeightAdjustableInput } from 'components/AutoHeightAdjustableInput';
import { useResponsive } from 'hooks/useResponsive';
import { useAuth } from 'lib/providers/AuthProvider';
import { TMediaPostPayload, TPollPostPayload, TPostPayload, TPostType, TTextPostPayload, TUserableType } from 'lib/models/discussion';
import { useDiscussion } from 'lib/providers/DiscussionProvider';

import styles from './PostModal.module.scss';

const PostModal: React.FC<{ containerClassName?: string }> = ({ containerClassName }): ReactElement => {
  const [t] = useTranslation('discussions');
  const { user, basicParticipant } = useAuth();
  const { post, isPostModalOpen, clearCurrentPost, addPostReply } = useDiscussion();
  const inputRef = useRef<HTMLDivElement>(null);
  const inputElement = inputRef.current;
  const [postType, setPostType] = useState<TPostPayload['type']>(TPostType.TextPost);
  const [postContent, setPostContent] = useState<TPostPayload['content']>('');
  const [postImages, setPostImages] = useState<TMediaPostPayload['media']>([]);
  const [postPollOptions, setPostPollOptions] = useState<TPollPostPayload['poll_answer_options_attributes']>([]);
  const [isReplying, setIsReplying] = useState(false);
  const [vh, setVh] = useState(typeof window === 'undefined' ? 0 : window.visualViewport?.height);
  const [isFocused, setIsFocused] = useState(false);
  const screens = useResponsive();
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isPostModalOpen) {
      inputRef.current?.focus();
    }
  }, [inputRef, isPostModalOpen]);

  useEffect(() => {
    const modalContentElement = modalContentRef.current;
    if (isPostModalOpen && modalContentElement) {
      modalContentElement.scrollTop = modalContentElement.scrollHeight;
    }
  }, [modalContentRef, isPostModalOpen]);

  useEffect(() => {
    if (!isPostModalOpen || !modalContentRef.current) {
      return;
    }

    disableBodyScroll(modalContentRef.current);

    return clearAllBodyScrollLocks;
  }, [modalContentRef, isPostModalOpen]);

  const canSubmit = useMemo(() => {
    switch (postType) {
      case TPostType.TextPost:
        return postContent.trim().length > 0;
      case TPostType.MediaPost:
        return postImages.length > 0;
      case TPostType.PollPost:
        return postContent.trim().length > 0 && postPollOptions.length > 0 && postPollOptions.every((option) => option.value.trim().length > 0);
    }
  }, [postContent, postImages, postPollOptions]);

  const onSubmit = useCallback(async () => {
    if (!user || !post) return;

    const basicPayload = {
      parent_post_id: post.id,
      content: postContent,
      userable_type: capitalize(user.userable_type) as TUserableType,
    };

    let postPayload: TTextPostPayload | TMediaPostPayload | TPollPostPayload | null = null;

    switch (postType) {
      case TPostType.TextPost:
        postPayload = { ...basicPayload, type: TPostType.TextPost } as TTextPostPayload;
        break;
      case TPostType.MediaPost:
        postPayload = { ...basicPayload, type: TPostType.MediaPost, media: postImages } as TMediaPostPayload;
        break;
      case TPostType.PollPost:
        postPayload = { ...basicPayload, type: TPostType.PollPost, poll_answer_options_attributes: postPollOptions } as TPollPostPayload;
        break;
    }

    if (!postPayload) return;

    try {
      setIsReplying(true);
      await addPostReply(postPayload);
      clearCurrentPost();
    } catch (error) {
      console.error('Error adding post', error);
    } finally {
      setIsReplying(false);
    }
  }, [user, postContent, postImages, postPollOptions, addPostReply, clearCurrentPost]);

  useEffect(() => {
    if (!isPostModalOpen) {
      setPostType(TPostType.TextPost);
      setPostContent('');
      setPostImages([]);
      setPostPollOptions([]);
    }
  }, [isPostModalOpen]);

  const onImageUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    setPostType(TPostType.MediaPost);
    setPostImages((prev) => [...prev, ...files]);
  }, []);

  const onImageRemove = useCallback(
    (index: number) => {
      if (postImages.length === 1) {
        setPostType(TPostType.TextPost);
      }
      setPostImages((prev) => {
        const updatedImages = [...prev];
        updatedImages.splice(index, 1);
        return updatedImages;
      });
    },
    [postImages, setPostType]
  );

  const initializePollOptions = useCallback(() => {
    setPostType(TPostType.PollPost);
    setPostPollOptions([{ value: '' }, { value: '' }]);
  }, []);

  const handleAddOption = useCallback(() => {
    setPostPollOptions((prev) => [...prev, { value: '' }]);
  }, []);

  const updatePollOption = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, pollIndex: number) => {
      setPostPollOptions((prev) => {
        const updatedOptions = [...prev];
        updatedOptions[pollIndex] = { value: event.target.value };
        return updatedOptions;
      });
    },
    [setPostPollOptions]
  );

  const removeOption = useCallback((pollIndex: number) => {
    setPostPollOptions((prev) => {
      const updatedOptions = [...prev];
      updatedOptions.splice(pollIndex, 1);
      return updatedOptions;
    });
  }, []);

  const removePoll = useCallback(() => {
    setPostType(TPostType.TextPost);
    setPostPollOptions([]);
  }, []);

  const hasNoAdditionalAttachment = useMemo(() => {
    return postImages.length === 0 && postPollOptions.length === 0;
  }, [postImages, postPollOptions]);

  useEffect(() => {
    function listener() {
      const vh = window.visualViewport?.height;
      setVh(vh);
    }

    window.visualViewport?.addEventListener('resize', listener);

    return () => {
      window.visualViewport?.removeEventListener('resize', listener);
    };
  }, []);

  useEffect(() => {
    function onFocus() {
      setIsFocused(true);
    }

    function onBlur() {
      setIsFocused(false);
    }

    inputRef.current?.addEventListener('focus', onFocus);
    inputRef.current?.addEventListener('blur', onBlur);

    return () => {
      inputRef.current?.removeEventListener('focus', onFocus);
      inputRef.current?.removeEventListener('blur', onBlur);
    };
  }, [inputElement]);

  return (
    <Modal
      isOpen={isPostModalOpen}
      onRequestClose={clearCurrentPost}
      closeOnOverlayClick={false}
      contentClassName={cx(styles['modal-container'], containerClassName)}
      className={cx(styles['modal-wrapper'], { [styles['input-focussed']]: isFocused })}
      style={screens.sm ? { height: vh } : {}}
    >
      <section className={styles.modal}>
        <header className={styles.header}>
          <p className={styles['title']}>{t('post-modal.title')}</p>
          <IconButton iconName="close" className={styles.close} size="auto" onClick={clearCurrentPost} />
        </header>
        <main className={styles['modal-content']} ref={modalContentRef}>
          {post && <Post post={post} minimalView={true} className={styles['post']} />}
          <div className={styles['post-reply']}>
            <HuzzleUserAvatar user={basicParticipant} />
            <div className={styles['reply-content']}>
              <div className={styles['reply-text']}>
                <AutoHeightAdjustableInput
                  value={postContent}
                  onChange={setPostContent}
                  placeholder={t('post-modal.placeholder')}
                  className={styles['reply-text-input']}
                  ref={inputRef}
                />
              </div>
              <div
                className={cx(styles['post-reply-attachment-actions'], {
                  [styles['no-attachments']]: hasNoAdditionalAttachment,
                })}
              >
                {hasNoAdditionalAttachment || postImages.length ? (
                  <PostImageUploader className={styles['icon-button']} images={postImages} onImageUpload={onImageUpload} onRemove={onImageRemove} />
                ) : null}
                {hasNoAdditionalAttachment || postPollOptions.length ? (
                  <PostPollCreator
                    className={styles['icon-button']}
                    pollOptions={postPollOptions}
                    initializePollOptions={initializePollOptions}
                    handleAddOption={handleAddOption}
                    updatePollOption={updatePollOption}
                    removePoll={removePoll}
                    removeOption={removeOption}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </main>
        <footer className={styles.footer}>
          <ButtonV3 onClick={onSubmit} disabled={!canSubmit || isReplying} isLoading={isReplying} className={styles['reply-button']}>
            {t('post-modal.reply')}
          </ButtonV3>
        </footer>
      </section>
    </Modal>
  );
};

export default PostModal;

PostModal.displayName = 'PostModal';
