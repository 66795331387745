import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { noop } from 'lodash';
import cx from 'classnames';

import { PostFullScreenImages } from 'components/discussions';
import ActionMenu from 'components/ActionMenu';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';

import styles from './DiscussionsMedia.module.scss';

const DiscussionsMedia = ({ mediaSize = 'large' }: { mediaSize?: 'small' | 'large' }) => {
  const [t] = useTranslation('discussions');
  const { mediaAttachments } = useDiscussions();
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState<number | null>(null);

  const closeImagesModal = useCallback(() => {
    setFullScreenImageIndex(null);
  }, []);

  const actionOptions = useMemo(() => {
    return [
      {
        id: '1',
        label: t('media.hide-from-media'),
        danger: false,
        onClick: noop,
      },
    ];
  }, [t]);

  if (!mediaAttachments) {
    return null;
  }

  if (mediaAttachments.length === 0) {
    return <p className={styles['no-media']}>{t('no-content.media.title')}</p>;
  }

  return (
    <div className={styles.container}>
      {mediaAttachments.map(({ id, url, file_name }, mediaIdx) => (
        <div className={styles['image-container']} key={id}>
          <img src={url} alt={file_name} className={cx(styles.image, styles[mediaSize])} onClick={() => setFullScreenImageIndex(mediaIdx)} />
          <ActionMenu
            options={actionOptions}
            className={styles['action-menu']}
            triggerButtonClassName={styles['action-menu-button']}
            listClassName={styles['action-menu-list']}
          />
        </div>
      ))}
      {fullScreenImageIndex !== null && (
        <PostFullScreenImages startImageIndex={fullScreenImageIndex} images={mediaAttachments} onClose={closeImagesModal} />
      )}
    </div>
  );
};

DiscussionsMedia.displayName = 'DiscussionsMedia';

export default DiscussionsMedia;
