import React, { useMemo } from 'react';
import cx from 'classnames';

import type { TPollPost } from 'lib/models/discussion';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './PostPollOptions.module.scss';

type PostPollOptionsProps = {
  postId: string;
  pollOptions: TPollPost['poll_answer_options'];
  is_post_approved: boolean;
  className?: string;
};

const PostPollOptions: React.FC<PostPollOptionsProps> = ({ postId, pollOptions, is_post_approved, className }) => {
  const { answerOnPoll, isManagerPage, currentUserId } = useDiscussions();
  const { isCandidate, managerSociety, recruiterCompany, candidate } = useAuth();

  const { answers, maximum_percentage } = useMemo(() => {
    const totalAnswers = pollOptions.reduce((acc, curr) => acc + curr.poll_answers.length, 0);
    const options = pollOptions.map((pollOption) => {
      const percentage = totalAnswers > 0 ? Math.round((pollOption.poll_answers.length / totalAnswers) * 100) : 0;
      return {
        id: pollOption.id,
        text: pollOption.value,
        percentage_count: percentage,
        percentage_text: `${percentage}%`,
      };
    });

    const maximum_percentage = options.reduce((max, curr) => {
      max = curr.percentage_count > max ? curr.percentage_count : max;
      return max;
    }, 0);

    return { answers: options, maximum_percentage };
  }, [pollOptions]);

  const userAnswer = useMemo(() => {
    const answer = pollOptions.find((option) => option.poll_answers.some((pollAnswer) => pollAnswer.user_id === currentUserId));
    return answer?.id;
  }, [pollOptions, currentUserId]);

  const canShowResults = useMemo(() => {
    return isManagerPage ? true : !!userAnswer;
  }, [isManagerPage, userAnswer]);

  const answeredUserImage = useMemo(() => {
    return isCandidate ? candidate?.avatar_url : managerSociety?.icon_url || recruiterCompany?.icon_url;
  }, [isCandidate, candidate, managerSociety, recruiterCompany]);

  return (
    <div className={cx(styles.container, className)}>
      {answers.map((answer) => {
        const percentage = answer.percentage_text;
        return (
          <div
            key={answer.id}
            className={cx(styles['poll-option'], {
              [styles.disabled]: userAnswer,
              [styles.top]: is_post_approved && canShowResults && maximum_percentage ? answer.percentage_count === maximum_percentage : 0,
            })}
            onClick={() => !userAnswer && answerOnPoll({ postId, pollAnswerOptionId: answer.id })}
          >
            <span className={styles.text}>{answer.text}</span>
            {is_post_approved ? (
              <>
                <div className={styles['image-percentage']}>
                  {userAnswer === answer.id && answeredUserImage ? <img src={answeredUserImage} className={styles['user-image']} /> : null}
                  {canShowResults ? <span className={styles.percentage}>{percentage}</span> : null}
                </div>
                {canShowResults ? <div className={cx(styles.background)} style={{ width: percentage }}></div> : null}
              </>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

PostPollOptions.displayName = 'PostPollOptions';

export default PostPollOptions;
