import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { IconButton } from 'components';
import { HuzzleUserAvatar } from 'components/HuzzleUserAvatar';
import ActionMenu from 'components/ActionMenu';
import {
  PostDocument,
  PostImages,
  PostPollOptions,
  PostReply,
  StartPostReply,
  TimeAgo,
  postDeleteConfirmationModalInstance,
} from 'components/discussions';
import { ButtonV3, MessageContent } from 'components/ComponentV2';
import { useDiscussions } from 'lib/providers/DiscussionsProvider';
import { TPost, TPostState, TPostType, TUserableType } from 'lib/models/discussion';
import { useDiscussion } from 'lib/providers/DiscussionProvider';
import { TPostParticipant } from 'lib/models/student-society';
import { getBasicPostParticipant } from 'lib/utils/discussion';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './Post.module.scss';

type PostProps = {
  post: TPost;
  onApprove?: (postId: string) => void;
  onDecline?: (postId: string) => void;
  className?: string;
  minimalView?: boolean;
  scrollToPost?: boolean;
  showReplies?: boolean;
  onReplyClick?: (post: TPost) => void;
  onReadPost?: (postId: string) => void;
};

const Post = ({
  post,
  className = '',
  minimalView = false,
  onApprove,
  onDecline,
  scrollToPost,
  showReplies = true,
  onReplyClick,
  onReadPost,
}: PostProps) => {
  const [tCommon] = useTranslation('common');
  const [t] = useTranslation('discussions');
  const { setCurrentPost } = useDiscussion();
  const {
    deletePost,
    onLikePost,
    onUnlikePost,
    onCopyPostURL,
    isManagerPage,
    openLoginPopupForCandidate,
    isSocietyMember,
    onMessageUser,
    onPostView,
    postViewedRef,
    scrollIntoViewRef,
    isManagerUser,
    isRecruiterUser,
    currentUserId,
    likeLoadingPostId,
  } = useDiscussions();
  const postRef = useRef<HTMLDivElement>(null);
  const postContentRef = useRef<HTMLDivElement>(null);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const { id: postId, has_viewed } = post;
  const { recruiterCompany } = useAuth();
  const postedByUser = useMemo(() => getBasicPostParticipant(post), [post]);

  const confirmDeletePost = useCallback(async () => {
    try {
      await postDeleteConfirmationModalInstance({
        ownPost: !isManagerUser,
      });
      deletePost({ postId });
    } catch (error) {
      console.error(error);
    }
  }, [isManagerUser]);

  const openCurrentPostModal = useCallback(async () => {
    try {
      await openLoginPopupForCandidate();
      if (onReplyClick) {
        onReplyClick(post);
      } else {
        setCurrentPost(post);
      }
    } catch (error) {
      console.error(error);
    }
  }, [post, openLoginPopupForCandidate, onReplyClick, setCurrentPost]);

  const actionOptions = useMemo(() => {
    const options = [];
    if (post.user.userable_type !== TUserableType.Manager && isManagerUser) {
      options.push({
        id: '1',
        label: tCommon('message'),
        danger: false,
        onClick: () => onMessageUser(post),
      });
    }

    if (
      post.state === TPostState.Approved &&
      (isManagerUser || postedByUser.id === currentUserId || (isRecruiterUser && recruiterCompany?.id === postedByUser.id))
    ) {
      options.push({
        id: '2',
        label: tCommon('delete'),
        danger: true,
        onClick: confirmDeletePost,
      });
    }

    return options;
  }, [tCommon, isManagerUser, post, recruiterCompany, isRecruiterUser, postedByUser, currentUserId, confirmDeletePost, onMessageUser]);

  const { content, created_at, post_type, likes, comments, viewed_count } = post;
  const replies_count = comments.length;
  const likes_count = likes.length;

  const postReplies = useMemo(() => {
    return comments.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }, [comments]);

  const handleLikePost = useCallback(
    (postId: string) => {
      onLikePost({ postId });
    },
    [onLikePost]
  );

  const canBeReplied = useMemo(() => {
    if (isRecruiterUser) return true;

    switch (post.who_can_reply) {
      case TPostParticipant.ANYONE:
        return true;
      case TPostParticipant.NO_ONE:
        return false;
      case TPostParticipant.TEAM_ONLY:
        return isManagerUser;
      case TPostParticipant.MEMBERS_ONLY:
        return isManagerUser || isSocietyMember;
      default:
        return false;
    }
  }, [post, isRecruiterUser, isManagerUser, isSocietyMember]);

  const approvedPostActions = useMemo(() => {
    const likeInstance = likes.find((like) => like.user_id === currentUserId);
    return (
      <div className={styles.actions}>
        <IconButton
          iconName={likeInstance ? 'heart-filled' : 'heart'}
          size="auto"
          className={cx(styles['icon-button'], { [styles.disabled]: likeLoadingPostId === postId, [styles['icon-filled']]: likeInstance })}
          iconClassName={styles.icon}
          onClick={
            likeLoadingPostId
              ? undefined
              : likeInstance
              ? () => onUnlikePost({ postId: postId, likeId: likeInstance.id })
              : () => onLikePost({ postId: postId })
          }
        />
        {canBeReplied ? (
          <IconButton iconName="reply" size="auto" className={styles['icon-button']} iconClassName={styles.icon} onClick={openCurrentPostModal} />
        ) : null}
        <IconButton
          iconName="icon_link"
          size="auto"
          className={styles['icon-button']}
          iconClassName={styles.icon}
          onClick={() => onCopyPostURL(postId)}
        />
      </div>
    );
  }, [likes, postId, likeLoadingPostId, currentUserId, handleLikePost, openCurrentPostModal, onUnlikePost, canBeReplied]);

  const approvedPostStats = useMemo(() => {
    return (
      <div className={styles.stats}>
        {replies_count > 0 ? (
          <span
            className={cx(styles['stat'], { [styles['clickable']]: typeof onReplyClick !== 'undefined' })}
            onClick={() => onReplyClick && onReplyClick(post)}
          >
            {t('post.replies_count', {
              count: replies_count,
            })}
          </span>
        ) : null}
        {likes_count > 0 ? (
          <>
            {replies_count > 0 ? <div className={styles['dot-divider']}></div> : null}
            <span className={styles['stat']}>
              {t('post.likes_count', {
                count: likes_count,
              })}
            </span>
          </>
        ) : null}
        {isManagerPage && viewed_count > 0 ? (
          <>
            {replies_count > 0 || likes_count > 0 ? <div className={styles['dot-divider']}></div> : null}
            <span className={styles['stat']}>
              {t('post.views_count', {
                count: viewed_count,
              })}
            </span>
          </>
        ) : null}
      </div>
    );
  }, [t, isManagerPage, replies_count, likes_count, viewed_count]);

  const pendingPostActions = useMemo(() => {
    if (!onApprove && !onDecline) return;

    return (
      <div className={styles['pending-actions']}>
        {onDecline ? (
          <ButtonV3 className={styles['approve-button']} startIcon="close" variant="contained" color="secondary" onClick={() => onDecline(postId)}>
            {t('pending-posts.post.decline')}
          </ButtonV3>
        ) : null}
        {onApprove ? (
          <ButtonV3 className={styles['approve-button']} startIcon="tick-mark" onClick={() => onApprove(postId)}>
            {t('pending-posts.post.approve')}
          </ButtonV3>
        ) : null}
      </div>
    );
  }, [t, postId, onApprove, onDecline]);

  const replyPlaceholder = useMemo(() => {
    if (isManagerUser || !postedByUser.name) return t('reply.placeholder');

    return t('reply.to-placeholder', { name: postedByUser.name });
  }, [isManagerUser, postedByUser]);

  useEffect(() => {
    if (scrollToPost && postRef.current && !scrollIntoViewRef.current) {
      postRef.current.scrollIntoView({ behavior: 'instant' });
      scrollIntoViewRef.current = true;
    }
  }, [scrollToPost, scrollIntoViewRef]);

  const onView = useCallback(
    async (postId: string) => {
      if (postViewedRef.current[postId]) return;
      try {
        postViewedRef.current[postId] = true;
        await onPostView(postId);
        if (!has_viewed && onReadPost) {
          onReadPost(postId);
        }
      } catch (error) {
        postViewedRef.current[postId] = false;
      }
    },
    [postViewedRef, has_viewed, onReadPost, onPostView]
  );

  useEffect(() => {
    if (isManagerPage || !postContentRef.current || postViewedRef.current[postId]) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
          timerIdRef.current = null;
        }

        // Check if the element is in the viewport and has at least 50% visibility
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
          timerIdRef.current = setTimeout(() => {
            onView(postId);
          }, 1000);
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (postContentRef.current) {
      observer.observe(postContentRef.current);
    }

    return () => {
      if (postContentRef.current) {
        observer.unobserve(postContentRef.current);
      }
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [postContentRef, postViewedRef, postId, isManagerPage, onView]);

  return (
    <div className={cx(styles.container, className, { [styles.highlighted]: scrollToPost })} id={`discussion-${postId}`} ref={postRef}>
      <div className={styles['avatar-container']}>
        <HuzzleUserAvatar user={postedByUser} />
      </div>
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles['name-and-duration']}>
            {postedByUser.name ? <p className={styles.name}>{postedByUser.name}</p> : null}
            <p className={styles.duration}>
              <TimeAgo timestamp={created_at} />
            </p>
          </div>
          {actionOptions.length > 0 && (
            <ActionMenu options={actionOptions} triggerButtonClassName={styles['action-menu-button']} listClassName={styles['action-menu-list']} />
          )}
        </header>
        <div className={styles['post-content']} ref={postContentRef}>
          {content ? <MessageContent content={content}></MessageContent> : null}
          {post_type === TPostType.MediaPost ? <PostImages images={post.media} className={styles.images} /> : null}
          {post_type === TPostType.PollPost ? (
            <div className={styles['poll-options']}>
              <PostPollOptions pollOptions={post.poll_answer_options} is_post_approved={!minimalView} postId={post.id} />
            </div>
          ) : null}
          {post_type === TPostType.DocumentPost ? (
            <div className={styles['post-documents']}>
              {post.documents.map((document) => (
                <PostDocument key={document.id} filename={document.file_name} className={styles['post-document']} url={document.url} />
              ))}
            </div>
          ) : null}
        </div>
        {minimalView ? (
          pendingPostActions
        ) : (
          <>
            {approvedPostActions}
            {approvedPostStats}
            {showReplies ? (
              <div className={styles.replies}>
                {canBeReplied ? <StartPostReply onStartReply={openCurrentPostModal} placeholder={replyPlaceholder} /> : null}
                {postReplies.map((reply) => {
                  return <PostReply key={reply.id} postReply={reply} postId={postId} />;
                })}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

Post.displayName = 'Post';

export default Post;
