import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { IconButton } from 'components';

import { ButtonV3, InputV2 } from 'components/ComponentV2';
import { TPollPostPayload } from 'lib/models/discussion';

import styles from './PostPollCreator.module.scss';

type PostPollCreatorProps = {
  className?: string;
  pollOptions: TPollPostPayload['poll_answer_options_attributes'];
  initializePollOptions: () => void;
  handleAddOption: () => void;
  updatePollOption: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  removePoll?: () => void;
  removeOption: (index: number) => void;
};

const PostPollCreator: React.FC<PostPollCreatorProps> = ({
  className,
  pollOptions,
  initializePollOptions,
  handleAddOption,
  updatePollOption,
  removePoll,
  removeOption,
}) => {
  const [t] = useTranslation('discussions');
  return (
    <div
      className={cx(styles.container, {
        [styles['full-width']]: pollOptions.length > 0,
      })}
    >
      {pollOptions.length === 0 ? (
        <IconButton iconName="unordered_list" size="auto" className={className} iconClassName={styles.icon} onClick={initializePollOptions} />
      ) : null}
      <div className={styles['options']}>
        {pollOptions.map((option, index) => (
          <InputV2
            key={index}
            className={styles['option']}
            value={option.value}
            placeholder={index === 0 || index === 1 ? t(`new-post-modal.poll-creator.option-placeholder.${index}`) : ''}
            inputClassName={styles['option-input']}
            onChange={(event) => updatePollOption(event, index)}
            rightIcon={
              pollOptions.length > 2 && (
                <IconButton iconName="close" size="xxsmall" className={styles['remove-option']} onClick={() => removeOption(index)} />
              )
            }
          />
        ))}
        {pollOptions.length > 0 ? (
          <ButtonV3 color="secondary" onClick={handleAddOption} className={styles['add-option-button']}>
            {t('new-post-modal.poll-creator.add-option')}
          </ButtonV3>
        ) : null}
      </div>
      {pollOptions.length > 0 && removePoll ? (
        <div className={styles['actions']}>
          <ButtonV3 color="secondary" startIcon="cancel" borderless size="small" className={styles['remove']} onClick={removePoll}>
            {t('new-post-modal.poll-creator.remove')}
          </ButtonV3>
        </div>
      ) : null}
    </div>
  );
};

PostPollCreator.displayName = 'PostPollCreator';

export default PostPollCreator;
